<template>
  <a-modal
    class="brand-edit-modal"
    title="Редактирование брендов"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <div v-show="!(!loading && !confirmLoading)" class="w-100 py-5 my-5 text-center">
      <a-spin tip="Загрузка..."></a-spin>
    </div>
    <a-form v-show="!loading && !confirmLoading" v-model="brand" @submit.prevent="update">
      <div class="col-md-12">
        <div class="logo-upload">
          <div v-if="!editLogo" class="ant-upload-picture-card-wrapper logo-uploader">
            <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
              <span role="button" class="ant-upload">
                <a-button @click="editLogo = true" type="link">
                  <EditOutlined />
                  Изменить лого
                </a-button>
              </span>
            </div>
          </div>
          <div v-else>
            <a-upload
              name="logo_url"
              list-type="picture-card"
              class="logo-uploader"
              :show-upload-list="false"
              :headers="getHeaders()"
              :action="getUrl"
              :before-upload="beforeUpload"
              @change="uploadLogo"
            >
              <img v-if="logoUrl" :src="logoUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <PlusOutlined v-else></PlusOutlined>
                <div class="ant-upload-text">Логотип</div>
              </div>
            </a-upload>
          </div>
          <div class="mb-4 text-center" v-if="logoUrl">
            <a-button @click="removeImage" type="link" danger><CloseCircleOutlined />Удалить логотип</a-button>
          </div>
        </div>
        <a-form-item label="Название бренда">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <a-input
                    :disabled="!editName"
                    placeholder="Введите название бренда"
                    v-model:value="brand.name"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="row justify-content-end">
                  <a-button @click="editName = !editName" type="link">
                    <CloseCircleOutlined v-if="editName" />
                    <EditOutlined v-else />
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="Callback url">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <a-input
                    placeholder="Введите адрес"
                    :disabled="!editCallback"
                    v-model:value="brand.payment_callback_url"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="row justify-content-end">
                  <a-button @click="editCallback = !editCallback" type="link">
                    <CloseCircleOutlined v-if="editCallback" />
                    <EditOutlined v-else />
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="Источник ИКПУ">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <a-select
                    :disabled="!editSpic"
                    show-search
                    v-model:value="brand.spic_priority"
                  >
                    <a-select-option value="detail_only">Вытащить из деталей</a-select-option>
                    <a-select-option value="db_only">Вытащить из БД</a-select-option>
                    <a-select-option value="detail_first">Детали в приоритете</a-select-option>
                    <a-select-option value="db_first">БД в приоритете</a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row justify-content-end">
                  <a-button @click="editSpic = !editSpic" type="link">
                    <CloseCircleOutlined v-if="editSpic" />
                    <EditOutlined v-else />
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="Клиринг">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <a-select
                    :disabled="!editClearing"
                    show-search
                    v-model:value="brand.clearing_frequency"
                  >
                    <a-select-option value="day">День</a-select-option>
                    <a-select-option value="month">Месяц</a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row justify-content-end">
                  <a-button @click="editClearing = !editClearing" type="link">
                    <CloseCircleOutlined v-if="editClearing" />
                    <EditOutlined v-else />
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons-vue';
import { reactive, ref } from 'vue';
import { getBase64 } from "@/helpers/base64";
import { getHeaders } from "@/helpers/header"
import apiClient from "@/services/axios";
import {message, notification} from "ant-design-vue";
import Brand from "@/models/Brand";

const props = defineProps({
  brandIds: {
    type: Array,
    default: null,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let logoUrl = ref(''),
  editLogo = ref(false),
  editName = ref(false),
  editCallback = ref(false),
  editSpic = ref(false),
  editClearing = ref(false),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  companyName = ref(null),
  phone = ref(''),
  codeData = ref([]),
  spicModel = ref([]),
  codeModel = ref([]),
  brand = reactive(new Brand({}));

const
  uploadLogo = info => {
    logoUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      brand.logo_url = info.file.response.data
      getBase64(info.file['originFileObj'], base64Url => {
        logoUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = () => {
    brand.logo_url = ''
    logoUrl.value = ''

    confirmLoading.value = true;
    apiClient.post(`/brand/update-all`, {
      ids: props.brandIds,
      logo_url: ''
    }).then(response => {
      if (response) {
        confirmLoading.value = false;
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  beforeUpload = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/brand/upload'
  },
  resetForm = () => {
    Object.assign(brand, new Brand({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = {}
    if (editLogo.value) model.logo_url = brand['logo_url']
    if (editName.value) model.name = brand['name']
    if (editCallback.value) model.payment_callback_url = brand['payment_callback_url']
    if (editSpic.value) model.spic_priority = brand['spic_priority']
    if (editClearing.value) model.clearing_frequency = brand['clearing_frequency']

    return apiClient
      .put(`/brand/update-all`, {
        ...model,
        ids: props.brandIds,
      })
      .then(response => {
        if (response) {
          resetForm()
          if (response.data.data) {
            response.data.data.forEach((item) => {
              notification.warning({
                message: `ID: ${item.id}`,
                description: item.details,
              })
            })
          }
          modalVisible.value = false;
          confirmLoading.value = false;
          emit('reload')
        }
      }).catch(() => {
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
        emit('hide-modal')
        // console.log(err)
      }).finally(() => {
        confirmLoading.value = false;
        modalVisible.value = false;
        emit('reload')
        emit('hide-modal')
      })
  };

</script>

<style scoped>
@import "./edit.css";
</style>
