export default function Brand(attrs) {
  this.name = attrs.name ? attrs.name : ''
  this.logo_url = attrs.logo_url ? attrs.logo_url : ''
  this.payment_callback_url = attrs.payment_callback_url ? attrs.payment_callback_url : null
  this.payment_callback_secret = attrs.payment_callback_secret ? attrs.payment_callback_secret : null
  this.show_menu = attrs.show_menu ? attrs.show_menu : false
  this.check_products = attrs.check_products ? attrs.check_products : false
  this.receive_order = attrs.receive_order ? attrs.receive_order : false
  this.spic_priority = attrs.spic_priority ? attrs.spic_priority : 'detail_only'
  this.clearing_frequency = attrs.clearing_frequency ? attrs.clearing_frequency : 'day'
  this.send_notification = attrs.send_notification ? attrs.send_notification : 0
  this.default_product_label = attrs.default_product_label ? attrs.default_product_label : ''
  this.default_product_price = attrs.default_product_price ? attrs.default_product_price : ''
  this.default_product_spic = attrs.default_product_spic ? attrs.default_product_spic : ''
  this.default_product_code = attrs.default_product_code ? attrs.default_product_code : ''
  this.default_driver_id = attrs.default_driver_id ? attrs.default_driver_id : ''
  this.default_driver_name = attrs.default_driver_name ? attrs.default_driver_name : ''
  this.default_driver_phone = attrs.default_driver_phone ? attrs.default_driver_phone : ''
  this.default_driver_pinfl = attrs.default_driver_pinfl ? attrs.default_driver_pinfl : ''
  this.server_login = attrs.server_login ? attrs.server_login : ''
  this.server_pass = attrs.server_pass ? attrs.server_pass : ''
  this.server_url = attrs.server_url ? attrs.server_url : ''
  this.menu_id = attrs.menu_id ? attrs.menu_id : ''
  this.menu_secret = attrs.menu_secret ? attrs.menu_secret : ''
  this.server_url = attrs.server_url ? attrs.server_url : ''
  this.menu_price_type_id = attrs.menu_price_type_id ? attrs.menu_price_type_id : null
  this.priceTypes = attrs.priceTypes ? attrs.priceTypes : []
  this.self_employed = attrs.self_employed ? attrs.self_employed : false
  this.self_employed_pinfl = attrs.self_employed_pinfl ? attrs.self_employed_pinfl : null
}
