<template>
  <a-modal
    class="brand-edit-modal"
    title="Редактировать бренд"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="brand">
      <div class="col-md-12">
        <a-upload
          name="logo_url"
          list-type="picture-card"
          class="logo-uploader"
          :show-upload-list="false"
          :headers="getHeaders()"
          :action="getUrl"
          :before-upload="beforeUpload"
          @change="uploadImage"
        >
          <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
          <div v-else>
            <loading-outlined v-if="loading"></loading-outlined>
            <PlusOutlined v-else></PlusOutlined>
            <div class="ant-upload-text">Логотип</div>
          </div>
        </a-upload>
        <div class="mb-4 text-center" v-if="imageUrl">
          <a-button @click="removeImage" type="link" danger><CloseCircleOutlined />Удалить фотографию</a-button>
        </div>
        <a-form-item label="Название бренда">
          <a-input
            ref="brandName"
            placeholder="Введите название бренда"
            v-model:value="brand.name"
          />
        </a-form-item>
        <a-form-item label="MXIK товара по умолчанию">
          <div class="container">
            <div v-if="editDefaultSpic" class="row">
              <div class="col-md-10 px-0">
                <a-select
                    v-model:value="spicModel"
                    mode="multiple"
                    placeholder="Поиск икпу"
                    style="width: 100%"
                    :filter-option="false"
                    :options="spicData"
                    @change="changeSpic"
                    @search="fetchSpic($event)"
                    :not-found-content="spicFetching ? undefined : null"
                    @keydown.esc="!editDefaultSpic"
                >
                  <template v-if="spicFetching" #notFoundContent>
                    <a-spin size="small"/>
                  </template>
                </a-select>
              </div>
              <div class="col-md-2 px-0 justify-content-end row">
                <a-button @click="saveSpic" type="text" shape="round" class="save-button px-2">
                  <template #icon>
                    <SaveOutlined/>
                  </template>
                </a-button>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-10 px-0">
                <a-input v-model:value="brand.default_product_spic" placeholder="МХИК товара" disabled />
              </div>
              <div class="col-md-2 px-0 justify-content-end row">
                <a-button @click="onEditDefaultSpic" type="text" shape="round" class="px-2 pull-right">
                  <template #icon>
                    <EditOutlined/>
                  </template>
                </a-button>
              </div>
            </div>
          </div>
          <!--                <a-input-->
          <!--                  placeholder="MXIK товара"-->
          <!--                  v-model:value="brand.default_product_spic"-->
          <!--                />-->
        </a-form-item>
        <a-form-item label="Код упаковки товара по умолчанию">
          <div class="container">
            <div v-if="editDefaultCode" class="row">
              <div class="col-md-10 px-0">
                <a-select
                    v-model:value="codeModel"
                    v-if="codeData.length"
                    placeholder="Поиск код упаковки"
                    style="width: 100%"
                    :filter-option="false"
                    :options="codeData"
                    :not-found-content="codeFetching ? undefined : null"
                    @keydown.esc="!editDefaultCode"
                >
                  <template v-if="codeFetching" #notFoundContent>
                    <a-spin size="small"/>
                  </template>
                </a-select>
                <a-spin v-else size="small"/>
              </div>
              <div class="col-md-2 px-0 justify-content-end row">
                <a-button @click="saveCode" type="text" shape="round" class="save-button px-2">
                  <template #icon>
                    <SaveOutlined/>
                  </template>
                </a-button>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-10 px-0">
                <a-input v-model:value="brand.default_product_code" placeholder="Код упаковки товара" disabled />
              </div>
              <div class="col-md-2 px-0 justify-content-end row">
                <a-button @click="onEditDefaultCode" type="text" shape="round" class="px-2 pull-right">
                  <template #icon>
                    <EditOutlined/>
                  </template>
                </a-button>
              </div>
            </div>
          </div>
          <!--                <a-input-->
          <!--                  placeholder="Код упаковки товара"-->
          <!--                  v-model:value="brand.default_product_code"-->
          <!--                />-->
        </a-form-item>
        <a-form-item label="Название товара по умолчанию">
          <a-input
              placeholder="Название товара"
              v-model:value="brand.default_product_label"
          />
        </a-form-item>
        <a-form-item label="Цена товара по умолчанию">
          <a-input
              placeholder="Цена товара (в тийинах)"
              v-model:value="brand.default_product_price"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {
  PlusOutlined,
  EditOutlined,
  SaveOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons-vue';
import {ref, reactive, toRaw, onMounted, nextTick} from 'vue';
import { getHeaders } from "@/helpers/header"
import { getBase64 } from "@/helpers/base64";
import { message } from "ant-design-vue";
import apiClient from "@/services/axios";
import Brand from "@/models/Brand";
import {debounce} from "lodash";
import vuexstore from "store";

const props = defineProps({
  brandId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let imageUrl = ref(''),
  loading = ref(true),
  editDefaultSpic = ref(false),
  editDefaultCode = ref(false),
  spicFetching = ref(false),
  codeFetching = ref(false),
  spicData = ref([]),
  codeData = ref([]),
  spicModel = ref([]),
  codeModel = ref([]),
  lastFetchId = 0,
  confirmLoading = ref(false),
  modalVisible = ref(true),
  brandName = ref("brandName"),
  initialBrand = new Brand({}),
  brand = reactive(initialBrand);

const
  uploadImage = info => {
    imageUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      brand.logo_url = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        imageUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = () => {
    imageUrl.value = ''
    brand.logo_url = ''

    confirmLoading.value = true;
    apiClient.put(`/brand/update?id=${props.brandId}`, { logo_url: '' })
    .then(() => {
      // console.log(response)
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  beforeUpload = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/brand/upload'
  },
  resetForm = () => {
    Object.assign(brand, new Brand({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  changeSpic = (e) => {
    fetchCode(e?.[0])
  },
  fetchSpic = debounce((value) => {
    // if (value.length < 17)
    //   return false;

    lastFetchId += 1;
    const fetchId = lastFetchId;
    spicData.value = [];
    spicFetching.value = true;
    const accessToken = vuexstore.get('accessToken')
    const apiUrl = process.env.VUE_APP_BACKEND_URL;
    fetch(`${apiUrl}/cabinet/menu/fetch-spic?product=&value=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": `Bearer ${accessToken}`,
      },
    }).then(response => response.json()).then(({data}) => {
      if (fetchId !== lastFetchId) {
        // for fetch callback order
        return;
      }
      spicData.value = data;
      spicFetching.value = false;
    });
  }, 300),
  fetchCode = debounce((value) => {
    // if (value.length < 17)
    //   return false;

    lastFetchId += 1;
    const fetchId = lastFetchId;
    codeData.value = [];
    codeFetching.value = true;
    const accessToken = vuexstore.get('accessToken')
    const apiUrl = process.env.VUE_APP_BACKEND_URL;
    fetch(`${apiUrl}/cabinet/menu/fetch-code?product=&spic=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": `Bearer ${accessToken}`,
      },
    }).then(response => response.json()).then(({data}) => {
      if (fetchId !== lastFetchId) {
        // for fetch callback order
        return;
      }
      codeData.value = data;
      codeFetching.value = false;
    });
  }, 300),
  saveSpic = () => {
    let model
    if (spicModel.value) {
      if (spicModel.value.length) {
        model = spicData.value.find((spic) => spic?.value === spicModel.value.find(() => true))
      } else {
        model = spicData.value.find((spic) => spic?.value === spicModel?.value)
      }
    }
    if (!model)
      model = 'null'

    brand.default_product_spic = model?.code
    editDefaultSpic.value = false
    brand.spic_id = model?.value
    // console.log(brand.spic_id)
    // fetchCode(model?.value)
  },
  saveCode = () => {
    let model
    if (codeModel.value) {
      if (codeModel.value.length) {
        model = codeData.value.find((code) => code?.value === codeModel.value.find(() => true))
      } else {
        model = codeData.value.find((code) => code?.value === codeModel?.value)
      }
    }
    if (!model)
      model = 'null'

    brand.default_product_code = model?.code
    editDefaultCode.value = false
  },
  onEditDefaultSpic = () => {
    editDefaultSpic.value = true
  },
  onEditDefaultCode = async () => {
    if (brand.spic_id) {
      fetchCode(brand.spic_id)
    } else {
      fetchCode(await getSpicId())
    }
    editDefaultCode.value = true
  },
  getSpicId = async () => {
    let id = null;
    const accessToken = vuexstore.get('accessToken')
    const apiUrl = process.env.VUE_APP_BACKEND_URL;
    await fetch(`${apiUrl}/cabinet/menu/get-spic-id?spic=${brand.default_product_spic}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": `Bearer ${accessToken}`,
      },
    }).then(response => response.json()).then(({ data }) => { id = data });
    return id;
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(brand)

    return apiClient
      .put(`/brand/update?id=${props.brandId}`, model)
      .then(response => {
        if (response) {
          resetForm()
        }
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        confirmLoading.value = false;
        modalVisible.value = false;
        emit('reload')
        emit('hide-modal')
      })
  };

apiClient.get(`/brand/view?id=${props.brandId}`).then(response => {
  if (response) {
    imageUrl.value = response.data.data.logo_url
    let model = new Brand(response.data.data)
    Object.assign(brand, model)
  }
}).catch(() => {
  // console.log(err)
}).finally(() => {
  loading.value = false
})

onMounted(() => {
  nextTick(() => brandName.value?.focus())
  window.addEventListener('keydown', (e) => {
    if (e.key === 'Enter' && e.metaKey) {
      update()
    }
  });
})

</script>

<style scoped>
@import "./edit.css";
</style>
